import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import "typeface-roboto";
import { WaterMarkComponent } from "./components";
import DashboardComponent from "./dashboard";
import theme from "./theme";

const MainAppComponent: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <DashboardComponent />
    <WaterMarkComponent />
  </ThemeProvider>
);

export default MainAppComponent;
