import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import useHeaderStyles from "./header.styles";

interface HeaderComponentProps {
  title: string;
  iaqOrCo2: "Iaq" | "CO2";
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  title,
  iaqOrCo2,
}) => {
  const classes = useHeaderStyles();
  return (
    <>
      <Grid item className={classes.noGrow}>
        <Typography
          variant="h1"
          component="div"
          align="center"
          className={classes.title}
          noWrap
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        className={`${classes.noGrow} ${classes.root}`}
      >
        <Typography
          variant="h1"
          component="span"
          className={classes.labelText}
          noWrap
        >
          Ubicación
        </Typography>
        <Typography variant="h1" component="span" className={classes.labelText}>
          {iaqOrCo2 === "Iaq" ? (
            <span>IAQ</span>
          ) : (
            <span>
              CO<sub>2</sub> <small style={{ fontSize: ".5em" }}>(ppm)</small>
            </span>
          )}
        </Typography>
      </Grid>
    </>
  );
};

export default HeaderComponent;
