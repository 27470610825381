export type Co2Type = "good" | "moderate" | "bad";

export const CO2ColorMap = new Map<Co2Type, string>([
  ["good", "48,192,72"],
  ["moderate", "192,192,48"],
  ["bad", "192,103,48"],
]);

export interface Co2LutItem {
  type: Co2Type;
  minCo2: number;
  maxCo2: number;
}

const iaqLut: Co2LutItem[] = [
  {
    type: "good",
    minCo2: 0,
    maxCo2: 800,
  },
  {
    type: "moderate",
    minCo2: 801,
    maxCo2: 1200,
  },
  {
    type: "bad",
    minCo2: 1201,
    maxCo2: 9999,
  },
];

export const getCo2TypeByValue = (co2: number): Co2Type =>
  iaqLut.find((value) => value.minCo2 <= co2 && value.maxCo2 >= co2)?.type ||
  "good";
