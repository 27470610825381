import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Item, Iaq, CO2 } from "../../models";
import useItemStyles from "./item.styles";

interface ItemComponentProps {
  item: Item;
  type: "CO2" | "Iaq";
  slim?: boolean;
}

const ItemComponent: React.FC<ItemComponentProps> = ({ item, type, slim }) => {
  const classes = useItemStyles();
  const { IaqColorMap, getIaqTypeByValue } = Iaq;
  const { CO2ColorMap, getCo2TypeByValue } = CO2;
  const bgColor =
    type === "Iaq"
      ? IaqColorMap.get(getIaqTypeByValue(item.value))
      : CO2ColorMap.get(getCo2TypeByValue(item.value));
  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
      style={{
        background: `rgba(${bgColor},1)`,
      }}
    >
      <Typography
        variant={slim ? "h3" : "h2"}
        component="span"
        noWrap
        className={classes.text}
      >
        {item.place}
      </Typography>
      <Typography
        variant={slim ? "h3" : "h2"}
        component="span"
        className={classes.text}
      >
        {item.value}
      </Typography>
    </Grid>
  );
};

export default ItemComponent;
