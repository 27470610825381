import { IndoorLocation, Measurement } from "../../models";
import { CAIRO_API_INDOOR } from "./api-url.service";
import { mapIndoorLocationToMeasurement } from "./api.mapper";

const getCo2 = (locationId: number, rooms: number[]): Promise<Measurement> =>
  fetch(`${CAIRO_API_INDOOR}${locationId}`)
    .then((result) => result.json())
    .then((data) => (data as IndoorLocation[]).pop())
    .then((location) =>
      location
        ? mapIndoorLocationToMeasurement(rooms)(location)
        : { locationName: "", rooms: [] }
    );

export default getCo2;
