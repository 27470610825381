import { makeStyles } from "@material-ui/core/styles";

const useHeaderStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    background: theme.palette.text.primary,
    color: theme.palette.background.default,
    marginBottom: theme.spacing(2),
  },
  noGrow: {
    flexGrow: 0,
  },
  root: {
    borderBottom: "1px solid",
    padding: theme.spacing(3),
  },
  labelText: {
    fontWeight: "bold",
  },
}));

export default useHeaderStyles;
