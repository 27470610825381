import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useWaterMarkStyles = makeStyles((theme) => ({
  gmmLogo: {
    position: "fixed",
    bottom: theme.spacing(),
    left: theme.spacing(),
    height: theme.spacing(8),
    maxWidth: theme.spacing(15),
    zIndex: 1,
  },
  customerLogo: {
    position: "fixed",
    bottom: theme.spacing(),
    right: theme.spacing(),
    height: theme.spacing(10),
    maxWidth: theme.spacing(20),
    zIndex: 1,
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export const WaterMarkComponent: React.FC = () => {
  const classes = useWaterMarkStyles();

  return (
    <>
      <div className={classes.gmmLogo}>
        <img src="/gmm.svg" className={classes.logo} alt="Logo" />
      </div>
      <div className={classes.customerLogo}>
        <img src="/escudo.new.svg" className={classes.logo} alt="Escudo" />
      </div>
    </>
  );
};
