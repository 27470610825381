import { makeStyles } from "@material-ui/core/styles";

const useDashboardStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  header: {
    flexGrow: 0,
    borderBottom: "1px solid",
  },
  content: {
    flexGrow: 1,
    marginBottom: theme.spacing(10),
  },
}));

export default useDashboardStyles;
