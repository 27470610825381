import { useEffect, useState } from "react";
import { Item, Measurement } from "../models";
import { APIService } from "../services";

interface DisplayParams {
  locationId: number;
  rooms: number[];
  maxItems: number;
}

const arrayChunk = <T>(array: T[], length: number): T[][] =>
  !array.length
    ? []
    : [array.slice(0, length)].concat(arrayChunk(array.slice(length), length));

const sortMeasurements = (rooms: number[]) => (
  measurements: Measurement
): Measurement => ({
  locationName: measurements.locationName,
  rooms: measurements.rooms.sort(
    (roomA, roomB) => rooms.indexOf(roomA.id) - rooms.indexOf(roomB.id)
  ),
});

const useDashboard = () => {
  const [displayParams, setDisplayParams] = useState<DisplayParams>();
  const [totalIndoorItems, setTotalIndoorItems] = useState<Measurement>();
  const [chunkedRooms, setChunkedRooms] = useState<Item[][]>();
  const [indoorItems, setIndoorItems] = useState<Measurement>();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setDisplayParams({
      locationId: parseInt(params.get("locationId") || "0"),
      maxItems: parseInt(params.get("maxItems") || "6"),
      rooms: (params.get("rooms") || "").split(",").map((el) => parseInt(el)),
    });
  }, []);

  useEffect(() => {
    if (displayParams) {
      const { rooms, locationId } = displayParams;
      APIService.getCo2(locationId, rooms)
        .then(sortMeasurements(rooms))
        .then(setTotalIndoorItems);
      setInterval(() => {
        APIService.getCo2(locationId, rooms)
          .then(sortMeasurements(rooms))
          .then(setTotalIndoorItems);
      }, 60000);
    }
  }, [displayParams]);

  useEffect(() => {
    if (totalIndoorItems && displayParams) {
      const { rooms } = totalIndoorItems;
      const innerChunkedRooms = arrayChunk(rooms, displayParams.maxItems);
      setChunkedRooms(innerChunkedRooms);
    }
  }, [totalIndoorItems, displayParams]);

  useEffect(() => {
    if (chunkedRooms) {
      setTimeout(() => {
        setPage(page >= chunkedRooms.length - 1 ? 0 : page + 1);
      }, 10000);
    }
  }, [chunkedRooms, page]);

  useEffect(() => {
    if (chunkedRooms && totalIndoorItems) {
      setIndoorItems({
        locationName: totalIndoorItems.locationName,
        rooms: chunkedRooms[page],
      });
    }
  }, [page, chunkedRooms, totalIndoorItems]);

  return { indoorItems, displayParams };
};

export default useDashboard;
