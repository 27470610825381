import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./main-app";

const urlParams = new URLSearchParams(window.location.search);
const rotation = urlParams.get("rotation");
rotation === "90" &&
  document.getElementsByTagName("body")[0].setAttribute("class", "rotate90");
rotation === "-90" &&
  document.getElementsByTagName("body")[0].setAttribute("class", "rotate-90");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
