import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { HeaderComponent, ItemComponent } from "../components";
import useDashboard from "./dashboard.hook";
import useDashboardStyles from "./dashboard.styles";

const DashboardComponent: React.FC = () => {
  const { indoorItems } = useDashboard();
  const classes = useDashboardStyles();

  const slim = (indoorItems && indoorItems.rooms?.length > 5) || false;

  return !!indoorItems ? (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      className={classes.root}
    >
      <HeaderComponent title={indoorItems.locationName} iaqOrCo2="CO2" />
      <Grid
        item
        container
        direction="column"
        justify="space-evenly"
        alignItems="stretch"
        className={classes.content}
      >
        {indoorItems.rooms?.map((item) => (
          <ItemComponent key={item.place} item={item} type="CO2" slim={slim} />
        ))}
      </Grid>
    </Grid>
  ) : (
    <CircularProgress color="secondary" />
  );
};

export default DashboardComponent;
