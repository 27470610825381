export type IaqType =
  | "good"
  | "moderate"
  | "unhealthySensitive"
  | "unhealthy"
  | "veryUnhealthy"
  | "hazardous";

export const IaqColorMap = new Map<IaqType, string>([
  ["good", "0,255,0"],
  ["moderate", "255,255,0"],
  ["unhealthySensitive", "255,128,0"],
  ["unhealthy", "255,0,0"],
  ["veryUnhealthy", "128,0,255"],
  ["hazardous", "0,0,0"],
]);

export interface IaqLutItem {
  type: IaqType;
  minIaq: number;
  maxIaq: number;
}

const iaqLut: IaqLutItem[] = [
  {
    type: "good",
    minIaq: 0,
    maxIaq: 50,
  },
  {
    type: "moderate",
    minIaq: 51,
    maxIaq: 100,
  },
  {
    type: "unhealthySensitive",
    minIaq: 101,
    maxIaq: 150,
  },
  {
    type: "unhealthy",
    minIaq: 151,
    maxIaq: 200,
  },
  {
    type: "veryUnhealthy",
    minIaq: 201,
    maxIaq: 300,
  },
  {
    type: "hazardous",
    minIaq: 301,
    maxIaq: 500,
  },
];

export const getIaqTypeByValue = (iaq: number): IaqType =>
  iaqLut.find((value) => value.minIaq <= iaq && value.maxIaq >= iaq)?.type ||
  "good";
