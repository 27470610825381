import { IndoorLocation, IndoorSensor, Item, Measurement } from "../../models";

export const mapIndoorLocationToMeasurement = (rooms: number[]) => ({
  locationName,
  sensors,
}: IndoorLocation): Measurement => ({
  locationName,
  rooms: sensors
    ? sensors
        .filter((el) => rooms.includes(+el.roomId))
        .map(mapSensorsToRoomItem)
    : [],
});

export const mapSensorsToRoomItem = ({
  roomId,
  roomName,
  measurements,
}: IndoorSensor): Item => ({
  id: +roomId,
  place: roomName,
  value: measurements.pop()?.co2 || 0,
});
