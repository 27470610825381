import { makeStyles } from "@material-ui/core/styles";

const useItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    zIndex: 10,
    boxShadow: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px rgba(0,0,0,.4)`,
  },
  text: {
    fontWeight: "bold",
  },
}));

export default useItemStyles;
